<template>
  <tr class="w-full hover:bg-grey-lightest my-1" :style="{'background-color':rowColor}">
    <td>
      <div class="brand-checkbox my-auto mx-auto">
        <input
          class=""
          :checked="checked"
          type="checkbox"
          :disabled="isFreelancer"
          :id="`ch-${question.id}`"
          @click="$emit('selectQuestion')"
        />
        <label :for="`ch-${question.id}`"></label>
      </div>
    </td>
    <td v-text="question.id"></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.game_type_id.required &&
        tagColumns.game_type_id.value
      "
      v-text="mainType"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.game_subtype_id.required &&
        tagColumns.game_subtype_id.value
      "
      v-text="subType"
    >
      Тип игры
    </td>
    <td
      v-if="
        tagColumns &&
        tagColumns.game_topic_id.required &&
        tagColumns.game_topic_id.value
      "
      v-text="topic"
    >
      Тематика
    </td>
    <td
      v-if="
        tagColumns && tagColumns.tour_id.required && tagColumns.tour_id.value
      "
      v-text="question.tour_id || 'Не указано'"
    ></td>
    <td class="mr-auto" v-text="question.question.text"></td>
    <td class="mr-auto" v-text="question.answer.text"></td>
    <td>{{ question.published_at | date }}</td>
    <td
      v-if="
        tagColumns &&
        tagColumns.slide_type_id.required &&
        tagColumns.slide_type_id.value
      "
      v-text="question.slide_type || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.pack_number.required &&
        tagColumns.pack_number.value
      "
      v-text="question.pack_number || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.is_special.required &&
        tagColumns.is_special.value
      "
      v-text="question.is_special ? 'Да' : 'Нет'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.is_adaptive.required &&
        tagColumns.is_adaptive.value
      "
      v-text="question.is_adaptive ? 'Да' : 'Нет'"
    ></td>
    <td
      v-if="tagColumns && tagColumns.theme.required && tagColumns.theme.value"
      v-text="question.tags.theme.name || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns && tagColumns.picture.required && tagColumns.picture.value
      "
      v-text="question.tags.picture.name || 'Не указано'"
    ></td>
    <td
      v-if="tagColumns && tagColumns.blitz.required && tagColumns.blitz.value"
      v-text="question.tags.blitz.name || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.company_type.required &&
        tagColumns.company_type.value
      "
      v-text="question.tags.company_type.name || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.company_name.required &&
        tagColumns.company_name.value
      "
      v-text="question.company_name || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.complexity.required &&
        tagColumns.complexity.value
      "
      v-text="question.tags.complexity.name || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.question_type.required &&
        tagColumns.question_type.value
      "
      v-text="question.tags.question_type.name || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.includedRegions.required &&
        tagColumns.includedRegions.value
      "
      v-text="
        question.includedRegions.map((e) => e.name).join(', ') || 'Не указано'
      "
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.excludedRegions.required &&
        tagColumns.excludedRegions.value
      "
      v-text="
        question.excludedRegions.map((e) => e.name).join(', ') || 'Не указано'
      "
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.musical_answer_type.required &&
        tagColumns.musical_answer_type.value
      "
      v-text="question.tags.musical_answer_type.name || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.direction.required &&
        tagColumns.direction.value
      "
      v-text="question.tags.direction.name || 'Не указано'"
    ></td>
    <td
      v-if="tagColumns && tagColumns.hit.required && tagColumns.hit.value"
      v-text="question.tags.hit.name || 'Не указано'"
    ></td>
    <td
      class="w-64"
      style="word-break: break-all"
      v-if="tagColumns && tagColumns.source.required && tagColumns.source.value"
      v-text="question.source || 'Не указано'"
    ></td>
    <td
      v-if="
        tagColumns &&
        tagColumns.music_region.required &&
        tagColumns.music_region.value
      "
      v-text="question.tags.music_region.name || 'Не указано'"
    ></td>
    <td>
      <router-link
        v-if="isFreelancer || isAuthor || isCorrector || isEditor"
        :to="{ name: 'question', params: { questionId: question.id } }"
      >
        <font-awesome-icon
          class="text-black text-base"
          :icon="['fas', 'eye']"
        ></font-awesome-icon>
      </router-link>
    </td>
    <td
      class="border-r-0"
      v-if="!isFreelancer && !isAuthor && (isCorrector || isEditor)"
    >
      <a
        href=""
        class="text-grey-darkest no-underline hover:text-grey"
        @click.prevent="remove(question.id)"
      >
        <trash></trash>
      </a>
    </td>
  </tr>
</template>

<script>
import Trash from "../../icons/trash";

export default {
  components: { Trash },
  props: ["question", "tagColumns", "checked", "gameCategories"],
  computed: {
    mainType() {
      return this.question.main_type;
    },
    subType() {
      return this.question.sub_type;
    },
    topic() {
      return this.question.topic;
    },
    isCorpLeague() {
      return this.question.game_type.toLowerCase().includes("корпоратив");
    },
    isLive() {
      return this.question.main_type.toLowerCase().includes("live");
    },
    isTuts() {
      return this.question.game_type.toLowerCase().includes("туц туц");
    },
    rowColor() {
      return this.question.product_appearance.questionsColor ? this.question.product_appearance.questionsColor : "#f9eff9";
    },
  },
  methods: {
    remove(id) {
      this.$emit("removeQuestion", [id]);
    },
  },
};
</script>

<style scoped>
@tailwind utilities;

td {
  @apply .text-left .p-2 .font-sans .text-sm .border-b .border-r .border-grey-light;
}
</style>
