var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"w-full hover:bg-grey-lightest my-1",style:({'background-color':_vm.rowColor})},[_c('td',[_c('div',{staticClass:"brand-checkbox my-auto mx-auto"},[_c('input',{attrs:{"type":"checkbox","disabled":_vm.isFreelancer,"id":("ch-" + (_vm.question.id))},domProps:{"checked":_vm.checked},on:{"click":function($event){return _vm.$emit('selectQuestion')}}}),_c('label',{attrs:{"for":("ch-" + (_vm.question.id))}})])]),_c('td',{domProps:{"textContent":_vm._s(_vm.question.id)}}),(
      _vm.tagColumns &&
      _vm.tagColumns.game_type_id.required &&
      _vm.tagColumns.game_type_id.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.mainType)}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.game_subtype_id.required &&
      _vm.tagColumns.game_subtype_id.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.subType)}},[_vm._v("\n    Тип игры\n  ")]):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.game_topic_id.required &&
      _vm.tagColumns.game_topic_id.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.topic)}},[_vm._v("\n    Тематика\n  ")]):_vm._e(),(
      _vm.tagColumns && _vm.tagColumns.tour_id.required && _vm.tagColumns.tour_id.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tour_id || 'Не указано')}}):_vm._e(),_c('td',{staticClass:"mr-auto",domProps:{"textContent":_vm._s(_vm.question.question.text)}}),_c('td',{staticClass:"mr-auto",domProps:{"textContent":_vm._s(_vm.question.answer.text)}}),_c('td',[_vm._v(_vm._s(_vm._f("date")(_vm.question.published_at)))]),(
      _vm.tagColumns &&
      _vm.tagColumns.slide_type_id.required &&
      _vm.tagColumns.slide_type_id.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.slide_type || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.pack_number.required &&
      _vm.tagColumns.pack_number.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.pack_number || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.is_special.required &&
      _vm.tagColumns.is_special.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.is_special ? 'Да' : 'Нет')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.is_adaptive.required &&
      _vm.tagColumns.is_adaptive.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.is_adaptive ? 'Да' : 'Нет')}}):_vm._e(),(_vm.tagColumns && _vm.tagColumns.theme.required && _vm.tagColumns.theme.value)?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.theme.name || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns && _vm.tagColumns.picture.required && _vm.tagColumns.picture.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.picture.name || 'Не указано')}}):_vm._e(),(_vm.tagColumns && _vm.tagColumns.blitz.required && _vm.tagColumns.blitz.value)?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.blitz.name || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.company_type.required &&
      _vm.tagColumns.company_type.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.company_type.name || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.company_name.required &&
      _vm.tagColumns.company_name.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.company_name || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.complexity.required &&
      _vm.tagColumns.complexity.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.complexity.name || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.question_type.required &&
      _vm.tagColumns.question_type.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.question_type.name || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.includedRegions.required &&
      _vm.tagColumns.includedRegions.value
    )?_c('td',{domProps:{"textContent":_vm._s(
      _vm.question.includedRegions.map(function (e) { return e.name; }).join(', ') || 'Не указано'
    )}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.excludedRegions.required &&
      _vm.tagColumns.excludedRegions.value
    )?_c('td',{domProps:{"textContent":_vm._s(
      _vm.question.excludedRegions.map(function (e) { return e.name; }).join(', ') || 'Не указано'
    )}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.musical_answer_type.required &&
      _vm.tagColumns.musical_answer_type.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.musical_answer_type.name || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.direction.required &&
      _vm.tagColumns.direction.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.direction.name || 'Не указано')}}):_vm._e(),(_vm.tagColumns && _vm.tagColumns.hit.required && _vm.tagColumns.hit.value)?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.hit.name || 'Не указано')}}):_vm._e(),(_vm.tagColumns && _vm.tagColumns.source.required && _vm.tagColumns.source.value)?_c('td',{staticClass:"w-64",staticStyle:{"word-break":"break-all"},domProps:{"textContent":_vm._s(_vm.question.source || 'Не указано')}}):_vm._e(),(
      _vm.tagColumns &&
      _vm.tagColumns.music_region.required &&
      _vm.tagColumns.music_region.value
    )?_c('td',{domProps:{"textContent":_vm._s(_vm.question.tags.music_region.name || 'Не указано')}}):_vm._e(),_c('td',[(_vm.isFreelancer || _vm.isAuthor || _vm.isCorrector || _vm.isEditor)?_c('router-link',{attrs:{"to":{ name: 'question', params: { questionId: _vm.question.id } }}},[_c('font-awesome-icon',{staticClass:"text-black text-base",attrs:{"icon":['fas', 'eye']}})],1):_vm._e()],1),(!_vm.isFreelancer && !_vm.isAuthor && (_vm.isCorrector || _vm.isEditor))?_c('td',{staticClass:"border-r-0"},[_c('a',{staticClass:"text-grey-darkest no-underline hover:text-grey",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.remove(_vm.question.id)}}},[_c('trash')],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }