<template>
  <div class="container mx-auto flex flex-col px-8 mx-4">
    <filter-panel :key="$route.query.trash" v-if="filterPanel"></filter-panel>
    <div class="container mx-auto flex justify-between">
      <questions-filter
        :main-url="'/api/questions?sort=-published_at,-id&include=slide_type&'"
        :route="$route"
      >
        <div class="w-full" slot-scope="{ link, values }">
          <pagination :base-url="link">
            <div
              class="w-full px-4 relative"
              slot-scope="{
                data: questions,
                processing,
                switchPage,
                fetchData,
              }"
            >
              <div class="w-full" v-if="!processing">
                <item-count :title="'вопросов'"></item-count>
                <!--      prevent user from copy  -->
                <!-- <div
                  class="
                    absolute
                    w-95
                    h-full
                    top-0
                    bottom-0
                    right-0
                    left-0
                    z-10
                  "
                  v-if="isFreelancer"
                ></div> -->
                <!-- сусусу -->
                <div
                  class="
                    rounded
                    border-grey
                    shadow-md
                    text-left
                    w-full
                    overflow-x-auto
                  "
                >
                  <table class="p-4 w-full">
                    <thead>
                      <tr>
                        <th>
                          <a
                            href=""
                            class="
                              text-grey-darkest
                              no-underline
                              hover:text-grey
                              mx-auto
                              my-auto
                            "
                            :class="[
                              {
                                'pointer-events-none cursor-default':
                                  isFreelancer,
                              },
                            ]"
                            title="Выбрать всё на этой странице"
                            @click.prevent="selectAll(questions)"
                          >
                            <font-awesome-icon
                              v-if="checked"
                              class="w-6"
                              :icon="['fas', 'angle-up']"
                            ></font-awesome-icon>
                            <font-awesome-icon
                              v-else
                              class="w-6"
                              :icon="['fas', 'angle-down']"
                            ></font-awesome-icon>
                          </a>
                        </th>
                        <th>№</th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.game_type_id.required &&
                            tagColumns.game_type_id.value
                          "
                        >
                          Категория
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.game_subtype_id.required &&
                            tagColumns.game_subtype_id.value
                          "
                        >
                          Тип игры
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.game_topic_id.required &&
                            tagColumns.game_topic_id.value
                          "
                        >
                          Тематика
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.tour_id.required &&
                            tagColumns.tour_id.value
                          "
                        >
                          Стандарт вопроса
                        </th>
                        <th class="mr-auto">Вопрос</th>
                        <th class="mr-auto">Ответ</th>
                        <th>Дата добавления</th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.slide_type_id.required &&
                            tagColumns.slide_type_id.value
                          "
                        >
                          Формат вопроса
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.pack_number.required &&
                            tagColumns.pack_number.value
                          "
                        >
                          Номер пакета
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.is_special.required &&
                            tagColumns.is_special.value
                          "
                        >
                          Спец. Игра
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.is_adaptive.required &&
                            tagColumns.is_adaptive.value
                          "
                        >
                          Адаптация
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.theme.required &&
                            tagColumns.theme.value
                          "
                        >
                          Текст
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.picture.required &&
                            tagColumns.picture.value
                          "
                        >
                          Картинки
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.blitz.required &&
                            tagColumns.blitz.value
                          "
                        >
                          Блиц
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.company_type.required &&
                            tagColumns.company_type.value
                          "
                        >
                          Компании
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.company_name.required &&
                            tagColumns.company_name.value
                          "
                        >
                          Имя компании
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.complexity.required &&
                            tagColumns.complexity.value
                          "
                        >
                          Сложность
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.question_type.required &&
                            tagColumns.question_type.value
                          "
                        >
                          Тип вопроса
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.includedRegions.required &&
                            tagColumns.includedRegions.value
                          "
                        >
                          Регион +
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.excludedRegions.required &&
                            tagColumns.excludedRegions.value
                          "
                        >
                          Регион -
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.musical_answer_type.required &&
                            tagColumns.musical_answer_type.value
                          "
                        >
                          Музыка
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.direction.required &&
                            tagColumns.direction.value
                          "
                        >
                          Стиль
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.hit.required &&
                            tagColumns.hit.value
                          "
                        >
                          Хит
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.source.required &&
                            tagColumns.source.value
                          "
                        >
                          Источник
                        </th>
                        <th
                          v-if="
                            tagColumns &&
                            tagColumns.music_region.required &&
                            tagColumns.music_region.value
                          "
                        >
                          Локал
                        </th>
                        <th>
                          <tag-select-panel
                            @tagColumns="updateColumns"
                          ></tag-select-panel>
                        </th>
                        <th
                          class="border-r-0"
                          v-if="
                            !isFreelancer &&
                            !isAuthor &&
                            (isCorrector || isEditor)
                          "
                        >
                          ddd
                          <a
                            href=""
                            class="
                              text-grey-darkest
                              no-underline
                              hover:text-grey
                            "
                            title="Удалить выбранные"
                            @click.prevent="
                              remove(selectedQuestions, fetchData)
                            "
                          >
                            <trash></trash>
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <question
                        v-for="(question, key) in questions"
                        :value="question"
                        :key="key"
                        :question="question"
                        :tag-columns="tagColumns"
                        :checked="selectedQuestions.indexOf(question.id) !== -1"
                        :game-categories="gameCategories"
                        @removeQuestion="remove([question.id], fetchData)"
                        @selectQuestion="selectQuestion(question.id)"
                      ></question>
                    </tbody>
                  </table>
                </div>
                <pagination-links @page="switchPage"></pagination-links>
                <portal to="restore-btn">
                  <button
                    v-if="$route.query.trash"
                    @click.prevent="restore(selectedQuestions, fetchData)"
                    class="
                      text-black
                      no-underline
                      px-4
                      py-2
                      ml-4
                      mr-1
                      my-auto
                      bg-grey
                      rounded
                      hover:bg-brand-light
                      whitespace-no-wrap
                      h-full
                    "
                  >
                    Вернуть
                  </button>
                </portal>
              </div>
              <spinner v-else></spinner>
            </div>
          </pagination>
        </div>
      </questions-filter>
      <portal to="filter-panel">
        <button
          @click.prevent="showPanel"
          class="
            text-white
            no-underline
            px-4
            py-2
            mr-1
            my-auto
            bg-brand
            rounded
            hover:bg-brand-light
            whitespace-no-wrap
            h-full
          "
        >
          Фильтрация
        </button>
      </portal>
    </div>
  </div>
</template>

<script>
import Question from "./question.vue";
import Pagination from "../../components/pagination.vue";
import PaginationLinks from "../../components/pagination-links.vue";
import QuestionsFilter from "../../components/questions/filter.vue";
import FilterPanel from "./filter-panel.vue";
import TagSelectPanel from "../../components/TagSelectPanel";
import Trash from "../../icons/trash";
import map from "lodash/map";
import axios from "axios";
import swal from "sweetalert2";
import ItemCount from "../../components/itemCount";
import Spinner from "../../components/spinner";

export default {
  components: {
    Spinner,
    ItemCount,
    Trash,
    TagSelectPanel,
    FilterPanel,
    QuestionsFilter,
    PaginationLinks,
    Pagination,
    Question,
  },
  watch: {
    $route(to, from) {
      this.clearSelected();
    },
  },
  data() {
    return {
      filterPanel: false,
      tagColumns: {
        show_additional: true,
        city: {
          required: false,
          value: false,
        },
        city_search_type: {
          required: false,
          value: false,
        },
        game_type_id: {
          required: true,
          value: false,
        },
        game_subtype_id: {
          required: true,
          value: false,
        },
        game_topic_id: {
          required: true,
          value: false,
        },
        main_type: {
          required: false,
          value: false,
        },
        sub_type: {
          required: false,
          value: false,
        },
        topic: {
          required: false,
          value: true,
        },
        pack_number: {
          required: true,
          value: false,
        },
        slide_type_id: {
          required: true,
          value: false,
        },
        tour_id: {
          required: true,
          value: true,
        },
        is_special: {
          required: true,
          value: false,
        },
        is_adaptive: {
          required: true,
          value: false,
        },
        theme: {
          required: true,
          value: false,
        },
        question_type: {
          required: true,
          value: false,
        },
        complexity: {
          required: true,
          value: false,
        },
        includedRegions: {
          required: true,
          value: false,
        },
        excludedRegions: {
          required: false,
          value: false,
        },
        hit: {
          required: true,
          value: false,
        },
        source: {
          required: true,
          value: false,
        },
        direction: {
          required: true,
          value: false,
        },
        musical_answer_type: {
          required: true,
          value: false,
        },
        music_region: {
          required: true,
          value: false,
        },
        picture: {
          required: true,
          value: false,
        },
        blitz: {
          required: true,
          value: false,
        },
        company_type: {
          required: true,
          value: false,
        },
        company_name: {
          required: true,
          value: false,
        },
      },
      selectedQuestions: [],
      checked: false,
      gameCategories: [],
    };
  },
  provide() {
    return {
      tagColumns: this.tagColumns,
    };
  },
  created() {
    this.getGameCategories();
  },
  methods: {
    showPanel() {
      this.filterPanel = !this.filterPanel;
    },
    updateColumns(columns) {
      this.tagColumns = columns;
    },
    selectAll(questions) {
      let questionIds = map(questions, "id");
      if (this.selectedQuestions.length === questionIds.length) {
        this.clearSelected();
      } else {
        this.selectedQuestions = questionIds;
        this.checked = true;
      }
    },
    selectQuestion(id) {
      if (this.selectedQuestions.indexOf(id) !== -1) {
        this.dropSelected(this.selectedQuestions, id);
      } else {
        this.selectedQuestions.push(id);
      }
    },
    clearSelected() {
      this.selectedQuestions = [];
      this.checked = false;
    },
    dropSelected(array, id) {
      let index = array.indexOf(id);
      if (index !== -1) array.splice(index, 1);
    },
    remove(ids, fetchData) {
      if (ids.length === 0) {
        swal(
          "Выберите вопросы!",
          "Необходимо выбрать элементы для удаления",
          "error"
        );
        return;
      }
      let message;
      let link;
      ids = ids.join("&ids[]=");
      if (this.$route.query.trash) {
        link = `/api/questions/force-delete?ids[]=${ids}`;
        message = "Вопрос будет удален, это действие невозможно отменить!";
      } else {
        link = `/api/questions?ids[]=${ids}`;
        message = "Вопрос будет отправлен в корзину!";
      }
      this.confirmDelete(message).then((result) => {
        axios.delete(link).then(() => {
          swal("Удалено!", "Вопрос удален.", "success");
          fetchData();
          this.clearSelected();
        });
      });
    },

    restore(ids, fetchData) {
      if (ids.length === 0) {
        swal(
          "Выберите вопросы!",
          "Необходимо выбрать элементы для восстановления",
          "error"
        );
        return;
      }
      ids = ids.join("&ids[]=");
      let message = "Вопрос будет восстановлен";
      let link = `/api/questions/restore?ids[]=${ids}`;
      this.confirmDelete(message).then((result) => {
        axios.patch(link).then(() => {
          swal("Восстановлено!", "Вы успешно вернули вопрос.", "success");
          fetchData();
          this.clearSelected();
        });
      });
    },

    confirmDelete(message) {
      return new Promise(function (resolve) {
        swal({
          title: "Вы уверены?",
          text: message,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Да удалить!",
          cancelButtonText: "Отмена",
        }).then((result) => {
          if (result.value) {
            resolve(result);
          }
        });
      });
    },
    getGameCategories() {
      axios.get(`/api/game-categories`).then((data) => {
        this.gameCategories = data.data;
      });
    },
  },
};
</script>

<style scoped>
@tailwind utilities;

th {
  @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
}
</style>
