/* eslint-disable vue/require-v-for-key */
/* eslint-disable vue/require-v-for-key */
<template>
    <div class="w-full px-4 mb-8">
        <div v-if="loaded" class="flex flex-wrap">
            <div>
                <a @click.prevent="clear" class="text-brand hover:bg-brand-light mb-2">
                    Сбросить все фильтры
                </a>
            </div>

            <div class="flex flex-wrap">
                <div class="flex w-3/4">
                    <FilterGameTypes ref="gameTypes" @input="gameTypes">
                    </FilterGameTypes>
                </div>
                <div class="flex flex-row pt-2 justify-start w-1/4">
                    <div class="w-1/3 flex flex-col">
                        <label for="is_special_filter" class="text-brand-dark font-semibold pr-3 pb-2">Спец.игра</label>
                        <input id="is_special_filter" class="select-none" v-model="filter.is_special" type="checkbox"/>
                    </div>
                    <div class="w-1/3 flex flex-col">
                      <label for="is_adaptive_filter" class="text-brand-dark font-semibold pr-3 pb-2">Адаптация</label>
                      <input id="is_adaptive_filter" class="select-none" v-model="filter.is_adaptive" type="checkbox"/>
                    </div>
                    <!--<div class="w-3/4 pt-4 -ml-2" v-show="question.is_special">-->
                    <!--<select v-model="currentValue.music_region" class="p-1 border-solid border-brand-light border w-full rounded">-->
                    <!--<option v-for="tag in tags.music_region" :key="tag.id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>-->
                    <!--</select>-->
                    <!--</div>-->
                </div>

                <!--<div class="flex-auto pt-2 pr-2 flex flex-col">-->
                <!--<label class="font-semibold text-brand pb-1">Номер пакета</label>-->
                <!--<div class="w-full mt-auto">-->
                <!--<input type="text" v-model="filter.pack_number" class="w-full p-2 border border-brand-light rounded" placeholder="Текст"/>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="flex-auto pt-2 pr-2 pl-2 flex flex-col">-->
                <!--<label class="font-semibold text-brand pb-1">Формат вопроса</label>-->
                <!--<div class="w-full mt-auto">-->
                <!--<select v-model="filter.slide_type_id" class="p-1 border-solid border-brand-light border w-full rounded">-->
                <!--<option v-for="tag in tags.slide_type_id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>-->
                <!--</select>-->
                <!--</div>-->
                <!--<div class="w-3/4 pt-4 -ml-2" v-show="question.is_special">-->
                <!--<select v-model="currentValue.music_region" class="p-1 border-solid border-brand-light border w-full rounded">-->
                <!--<option v-for="tag in tags.music_region" :key="tag.id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>-->
                <!--</select>-->
                <!--</div>-->
                <!--</div>-->
                <div class="w-1/4 pt-6 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Город</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.city" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.city" v-text="tag.name" :value="tag.id"
                                    class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div class="flex-auto pt-6 px-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Тип поиска</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.city_search_type"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option selected value="played" class="bg-brand rounded text-white hover:bg-brand-dark">
                                Сыграные
                                вопросы
                            </option>
                            <option value="not_played" class="bg-brand rounded text-white hover:bg-brand-dark">Не
                                сыграные
                                вопросы
                            </option>
                            <option value="changed" class="bg-brand rounded text-white hover:bg-brand-dark">Заменнные
                                вопросы
                            </option>
                        </select>
                    </div>
                </div>
                <div class="flex-auto pt-6 pr-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Формат вопроса</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.slide_type_id"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.slide_type_id" v-text="tag.name" :value="tag.id"
                                    class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-row w-1/4">
                    <div class="flex-auto pt-2 pr-2 flex flex-col w-1/2">
                        <label class="font-semibold text-brand pb-1">Номер пакета</label>
                        <div class="w-full mt-auto">
                            <input type="text" v-model="filter.pack_number"
                                   class="w-full p-2 border border-brand-light rounded"
                                   placeholder="Текст"/>
                        </div>
                    </div>
                    <div class="w-1/2"></div>
                </div>

                <!--<div class="flex w-1/4"></div>-->
                <hr class="w-full h-2px bg-grey-light mt-4">
                <div class="flex-auto pt-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Стандарт вопроса</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.tour_id"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option value="" class="bg-brand rounded text-white hover:bg-brand-dark">--Не выбрана--
                            </option>
                            <option value="1" class="bg-brand rounded text-white hover:bg-brand-dark">1</option>
                            <option value="2" class="bg-brand rounded text-white hover:bg-brand-dark">2</option>
                            <option value="3" class="bg-brand rounded text-white hover:bg-brand-dark">3</option>
                            <option value="4" class="bg-brand rounded text-white hover:bg-brand-dark">4</option>
                            <option value="5" class="bg-brand rounded text-white hover:bg-brand-dark">5</option>
                            <option value="6" class="bg-brand rounded text-white hover:bg-brand-dark">6</option>
                            <option value="7" class="bg-brand rounded text-white hover:bg-brand-dark">7</option>
                        </select>
                    </div>
                </div>
                <div class="flex-auto pt-2 px-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Тексты</label>
                    <div class="w-full mt-auto">
                        <search-select
                                v-model="filter.theme"
                                :options="tags.theme"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>
                <div class="flex-auto pt-2 pr-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Картинки</label>
                    <div class="w-full mt-auto">
                        <search-select
                                v-model="filter.picture"
                                :options="tags.picture"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>

                <div class="flex-auto pt-2 pr-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Блиц</label>
                    <div class="w-full mt-auto">
                        <search-select
                                v-model="filter.blitz"
                                :options="tags.blitz"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>

                <div class="flex-auto pt-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Тип вопроса</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.question_type"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.question_type" v-text="tag.name" :value="tag.id"
                                    class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div class="flex-auto pt-2 px-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Регион +</label>
                    <div class="w-full mt-auto">
                        <multi-select v-model="filter.includedRegions" :options="tags.includedRegions"
                                      class="border w-full rounded"></multi-select>
                    </div>
                </div>
                <div class="flex-auto pt-2 pr-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Регион -</label>
                    <div class="w-full mt-auto">
                        <multi-select v-model="filter.excludedRegions" :options="tags.excludedRegions"
                                      class="border w-full rounded"></multi-select>
                    </div>
                </div>
                <div class="flex flex-col w-1/4 pt-2">
                    <label class="font-semibold text-brand pb-1 ml-4">Сложность</label>
                    <div class="flex justify-between items-center">
                        <div v-for="tag in complexityTag[1]" class="flex px-1 items-center flex-row">
                            <p class="text-lg mx-1">{{tag.name}}</p>
                            <input type="radio" name="complexity" :value="tag.id" v-model="filter.complexity">
                        </div>
                        <div v-for="tag in complexityTag[0]" class="flex px-1 py-2 items-center h-full flex-row w-full">
                          <p class="text-lg mx-1">--</p>
                          <input type="radio" name="complexity" :value="tag.id" v-model="filter.complexity">
                        </div>
                    </div>
                </div>

                <hr class="w-full h-2px bg-grey-light mt-4">
                <div class="flex-auto pt-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Музыка</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.musical_answer_type"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.musical_answer_type" v-text="tag.name" :value="tag.id"
                                    class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div class="flex-auto pt-2 px-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Стиль</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.direction"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.direction" v-text="tag.name" :value="tag.id"
                                    class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div class="flex-auto pt-6 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Локал</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.music_region"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.music_region" v-text="tag.name" :value="tag.id"
                                    class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div class="flex pt-2 px-2 pt-6 flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1 pl-6">Хит</label>
                    <div class="w-full flex flex-row">
                        <div class="flex flex-row mx-3 items-center" v-for="(hit, key) in tags.hit" v-if="key !== 0" :key="key">
                            <label class="font-semibold text-brand pb-1 mx-1" v-text="hit.name"></label>
                            <input name="hit" v-model="filter.hit" :value="hit.id" type="radio">
                        </div>
                        <div class="flex px-1 py-2 items-center h-full flex-row w-full">
                            <p class="text-lg mx-1" v-text="'--'"></p>
                            <input type="radio" name="hit" :value="tags.hit[0].id" v-model="filter.hit">
                        </div>
                    </div>
                </div>

                <div class="pt-6 pr-2 flex flex-col w-1/4">
                    <label class="font-semibold text-brand pb-1">Компании</label>
                    <div class="w-full mt-auto">
                        <search-select
                                v-model="filter.company_type"
                                :options="tags.company_type"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>
                <div class="flex flex-row px-2 w-1/4">
                    <div class="flex-auto pt-6 pr-2 flex flex-col w-1/2">
                        <label class="font-semibold text-brand pb-1">Имя компании</label>
                        <div class="w-full mt-auto">
                            <input type="text" v-model="filter.company_name"
                                   class="w-full p-2 border border-brand-light rounded"
                                   placeholder="Текст"/>
                        </div>
                    </div>
                    <div class="w-1/2"></div>
                </div>

                <div style="width: 41.6666%"></div>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import axios from 'axios'
import FilterGameTypes from '../../components/FilterGameTypes.vue'
import SearchSelect from '../../components/SearchSelect.vue'
import MultiSelect from '../../components/MultiSelect'

export default {
  components: { MultiSelect, SearchSelect, FilterGameTypes },
  name: 'FilterPanel',
  data () {
    return {
      tags: [],
      typesFake: {
        query: {
          game_topic_id: '',
          game_type_id: 1,
        },
      },
      loaded: false,
      tag_local: false,
      tag_hit: false,
      filter: {
        slide_type_id: this.$route.query['slide_type_id'] || '',
        tour_id: this.$route.query['tour_id'] || '',
        pack_number: this.$route.query['pack_number'] || '',
        theme: this.$route.query['theme'] || '',
        // answer_type: this.$route.query['answer_type'] || '',
        question_type: this.$route.query['question_type'] || '',
        complexity: this.$route.query['complexity'] || '',
        // humor: this.$route.query['humor'] || '',
        // region: this.$route.query['region'] || '',
        includedRegions: [],
        excludedRegions: [],
        // track: this.$route.query['track'] || '',
        hit: this.$route.query['hit'] || '',
        direction: this.$route.query['direction'] || '',
        musical_answer_type: this.$route.query['musical_answer_type'] || '',
        music_region: this.$route.query['music_region'] || '',
        trash: this.$route.query['trash'] || '',
        like: this.$route.query['like'] || '',
        city: this.$route.query['city'] || '',
        city_search_type: this.$route.query['city_search_type'] || 'played',
        main_type: this.$route.query['main_type'] || '',
        sub_type: this.$route.query['sub_type'] || '',
        topic: this.$route.query['topic'] || '',
        picture: this.$route.query['picture'] || '',
        is_special: this.$route.query['is_special'] === 'false' ? false : this.$route.query['is_special'] || false,
        is_adaptive: this.$route.query['is_adaptive'] === 'false' ? false : this.$route.query['is_adaptive'] || false,
        game_type_id: this.$route.query['game_type_id'] || '',
        game_topic_id: this.$route.query['game_topic_id'] || '',
        blitz: this.$route.query['blitz'] || '',
        company_name: this.$route.query['company_name'] || '',
        company_type: this.$route.query['company_type'] || '',
      },
      complexityTag: {
        0: [],
        1: [],
      },
    }
  },
  created () {
    this.getTags()
  },
  watch: {

    'filter.main_type': {
      handler (val) {
        this.filter.sub_type = ''
        this.filter.topic = ''
      },
      deep: true,
    },
    'filter.sub_type': {
      handler (val) {
        this.filter.topic = ''
      },
      deep: true,
    },
    filter: {
      handler (val, oldVal) {
        if (this.loaded) {
          this.addFilters(val)
        }
      },
      deep: true,
      immediate: true,
    },
    '$route.query': {
      handler (val) {
        if (val['like']) {
          this.filter['like'] = val['like']
        }
      },
      deep: true,
    },
    tag_local () {
      this.filter.music_region = ''
      console.log(this.tag_local)
      if (this.tag_local === false) {
        let a = this.tags.music_region.filter(music_region => music_region.name === 'Мир')
        console.log(a)
        this.filter.music_region = a[0].id
      }
    },
    tag_hit () {
      this.filter.hit = ''
      if (this.tag_hit === false) {
        this.filter.hit = 2
      }
      if (this.tag_hit === true) {
        this.filter.hit = 1
      }
    },
    'filter.theme': function (val, oldval) {
      if (val !== '') {
        this.filter.musical_answer_type = ''
        this.filter.picture = ''
        this.filter.company_type = ''
        this.filter.blitz = ''
      }
    },
    'filter.musical_answer_type': function (val, oldval) {
      if (val !== '') {
        this.filter.theme = ''
        this.filter.picture = ''
        this.filter.company_type = ''
        this.filter.blitz = ''
      }
    },
    'filter.picture': function (val, oldval) {
      if (val !== '') {
        this.filter.musical_answer_type = ''
        this.filter.theme = ''
        this.filter.company_type = ''
        this.filter.blitz = ''
      }
    },
    'filter.company_type': function (val, oldval) {
      if (val !== '') {
        this.filter.musical_answer_type = ''
        this.filter.theme = ''
        this.filter.picture = ''
        this.filter.blitz = ''
      }
    },
    'filter.blitz': function (val, oldval) {
      if (val !== '') {
        this.filter.musical_answer_type = ''
        this.filter.theme = ''
        this.filter.company_type = ''
        this.filter.picture = ''
      }
    },
  },
  computed: {
    musicRegion () {
      return this.tags.music_region.filter(
        music_region => music_region.name !== 'Мир' && music_region.name !== '-- Не выбрано --')
    },
    subTypes () {
      if (this.filter.main_type) {
        return this.tags.gameCategories[this.filter.main_type].subType
      }
      return []
    },
    topics () {
      if (this.filter.main_type && this.filter.sub_type) {
        return this.tags.gameCategories[this.filter.main_type].subType[this.filter.sub_type].topic
      }
      if (this.filter.main_type) {
        return this.tags.gameCategories[this.filter.main_type].topic
      }
      return []
    },
  },
  methods: {
    getTags () {
      axios.get(`/api/tags`).then((data) => {
        this.tags = data.data
        this.filter.includedRegions = this.bindRegion(this.$route.query['includedRegions'])
        this.filter.excludedRegions = this.bindRegion(this.$route.query['excludedRegions'])
        this.loaded = true
        this.tagsComplexity()
      })
    },
    applySearchFilter (search, thems) {
      return thems.filter(them => them.name.toLowerCase().startsWith(search.toLowerCase()))
    },
    addFilters: debounce(function (filter) {
      let filterData = {}

      for (let key in filter) {
        if (filter[key] === '') {
          continue
        }
        if (key === 'includedRegions' || key === 'excludedRegions') {
          filterData[key] = filter[key].map(function (obj) {
            return obj.id
          })
          continue
        }
        filterData[key] = filter[key]
      }
      this.$router.push({ query: filterData })
    }, 500),
    clear () {
      for (let key in this.filter) {
        if (key === 'trash') {
          continue
        }
        if (key === 'like') {
          continue
        }
        if (key === 'city_search_type') {
          this.filter[key] = 'played'
          continue
        }
        this.filter[key] = ''
      }
      this.gameTypes(this.typesFake)
    },
    bindRegion (ids) {
      let result = []
      if (!ids || ids.lenght === 0) {
        return []
      }
      ids = ids instanceof Array ? ids : [ids]
      ids.forEach(id => {
        result.push(this.tags.includedRegions.filter(obj => obj.id == id)[0])
      })
      return result
    },
    tagsComplexity () {
      for (let i = 0; i < Object.keys(this.tags.complexity).length; i++) {
        if (this.tags.complexity[i].id === '') {
          this.complexityTag[0].push(this.tags.complexity[i])
        } else {
          this.complexityTag[1].push(this.tags.complexity[i])
        }
      }
    },
    gameTypes (gameTypeId) {
      if (gameTypeId) {
        this.filter.game_type_id = gameTypeId.query
      }
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;
</style>
